import React from 'react';
import makeBlur from '../utils/makeBlur';
import { runInContext } from 'vm';
import { posix } from 'path';

export class Canvas extends React.PureComponent {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    const { posx, posy, bgcol } = this.props;
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = (canvas.width = window.innerWidth);
    const height = (canvas.height = window.innerHeight);
    ctx.save();
    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'source-over';
    ctx.fillStyle = 'hsla(217, 35%, 5%, .8)';
    ctx.fillRect(0, 0, width, height);
    ctx.restore();

    const dots = [];
    const density = 20;
    const baseSize = 20;
    const diffSize = 30;

    const Dot = function() {
      const diff = 30;
      this.size = Math.floor(Math.random() * diffSize) + baseSize;
      this.color = bgcol;
      this.pos = {
        // 位置
        x: width / 2 + makeBlur(diff),
        y: height / 2 + makeBlur(diff),
      };
    };
    Dot.prototype = {
      update() {
        this.draw();
      },

      draw() {
        const diff = 120;

        function drawTriangle(a, b, c, d, e, f, color) {
          ctx.setTransform(1, 0, 0, 1, 0, 0);
          ctx.fillStyle = color;
          ctx.beginPath();
          ctx.moveTo(a, b);
          ctx.lineTo(c, d);
          ctx.lineTo(e, f);
          ctx.rotate(Math.floor(360));
        }

        function drawEquilateralTriangle(x, y, r, angle, color) {
          const a = x - r * Math.cos(((90 + angle) / 180) * Math.PI);
          const b = y - r * Math.sin(((90 + angle) / 180) * Math.PI);
          const c = x - r * Math.cos(((210 + angle) / 180) * Math.PI);
          const d = y - r * Math.sin(((210 + angle) / 180) * Math.PI);
          const e = x - r * Math.cos(((330 + angle) / 180) * Math.PI);
          const f = y - r * Math.sin(((330 + angle) / 180) * Math.PI);
          drawTriangle(a, b, c, d, e, f, color);
        }

        drawEquilateralTriangle(
          Math.floor(this.pos.x + makeBlur(posx)),
          Math.floor(this.pos.y + makeBlur(posy)),
          this.size,
          Math.floor(Math.random() * diff),
          this.color,
        );
        ctx.globalCompositeOperation = 'lighter';

        ctx.closePath();
        ctx.fill();
        ctx.restore();
      },
    };

    function update() {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      for (let i = 0; i < density; i += 1) {
        dots[i].update();
      }
    }

    function init() {
      for (let i = 0; i < density; i += 1) {
        dots.push(new Dot());
      }
      update();
    }
    init();
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}
