import React from 'react';
import { Canvas } from './Canvas2';

export class CanvasAnimation2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { angle: 0, posX: 0, posY: 0, bgcol: 'rgb(255, 255, 255)' };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }

  randomColor() {
    const r = Math.floor(Math.random() * 55);
    const g = Math.floor(Math.random() * 55);
    const b = Math.floor(Math.random() * 55);
    return `rgb(${r},${g},${b})`;
  }

  updatePositionStateX(prev: number) {
    let newNum = prev;
    return newNum >= 33 ? (newNum = 0) : (newNum += 2);
  }

  updatePositionStateY(prev: number) {
    let newNum = prev;
    return newNum >= 33 ? (newNum = 0) : (newNum += 2);
  }

  updateAnimationState() {
    const diff: number = 70;
    this.setState(prevState => ({
      posX: this.updatePositionStateX(prevState.posX) + Math.random() * diff - Math.random() * diff,
      posY: this.updatePositionStateY(prevState.posY) + Math.random() * diff - Math.random() * diff,
      bgcol: this.randomColor(),
    }));
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  // updateAnimationState() {
  //   this.setState(prevState => ({ angle: prevState.angle + 1, bgcol: this.randomColor() }));
  //   this.rAF = requestAnimationFrame(this.updateAnimationState);
  // }

  render() {
    return <Canvas angle={this.state.angle} posx={this.state.posX} posy={this.state.posY} bgcol={this.state.bgcol} />;
  }
}
