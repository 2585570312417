const colors = {
  primary: 'rgba(255, 255, 255, 0.7)', // Color Default Text
  link: '999', // Color for buttons or links
  bg: '#000', // Background color
  white: '#fff',
  grey: {
    dark: 'rgba(255, 255, 255, 0.25)',
    default: 'rgba(255, 255, 255, 0.5)',
    light: 'rgba(255, 255, 255, 0.7)',
    ultraLight: 'rgba(255, 255, 255, 0.9)',
  },
};

const transitions = {
  normal: '0.5s',
};

const fontSize = {
  small: '0.9rem',
  big: '2.9rem',
};

export default {
  colors,
  transitions,
  fontSize,
};
