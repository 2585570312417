import React from 'react';
import { runInContext } from 'vm';

export class Canvas extends React.PureComponent {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    const { angle, posx, posy, bgcol } = this.props;
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = (canvas.width = window.innerWidth);
    const height = (canvas.height = window.innerHeight);
    ctx.save();
    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'source-over';
    ctx.fillStyle = 'hsla(217, 35%, 5%, .8)';
    ctx.fillRect(0, 0, width, height);
    ctx.restore();

    const dots = [];
    const density = 70;
    const baseSize = 5;
    const diffSize = 400;

    const Dot = function() {
      this.size = Math.floor(Math.random() * diffSize) + baseSize;
      this.color = bgcol;
      this.pos = {
        // 位置
        // x: posx + Math.random() * 100 - Math.random() * 100,
        // y: posy + Math.random() * 100 - Math.random() * 100,
        x: width / 2 + Math.random() * posx - Math.random() * posx,
        y: height / 2 + Math.random() * posy - Math.random() * posy,
      };
    };
    Dot.prototype = {
      update() {
        this.draw();
      },

      draw() {
        ctx.globalCompositeOperation = 'lighter';
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.rect(-140, this.pos.y, window.innerWidth + 400, Math.random() * 30);
        ctx.fill();
        ctx.rotate(Math.random() * 360);
        ctx.restore();
      },
    };

    function update() {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      for (let i = 0; i < density; i += 1) {
        dots[i].update();
      }
    }

    function init() {
      for (let i = 0; i < density; i += 1) {
        dots.push(new Dot());
      }
      update();
    }
    init();
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}
