import React from 'react';
import makeBlur from '../utils/makeBlur';
import { Canvas } from './Canvas3';

export class CanvasAnimation3 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { angle: 0, posX: 0, posY: 0, bgcol: 'rgb(255, 255, 255)' };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
    this.flagX = true;
    this.flagY = true;
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }

  randomColor() {
    const r = Math.floor(Math.random() * 55);
    const g = Math.floor(Math.random() * 55);
    const b = Math.floor(Math.random() * 55);
    return `rgb(${r},${g},${b})`;
  }

  updatePositionStateX(prev: number) {
    let newNum = prev;
    const maxNum = 320;
    if (newNum >= maxNum) {
      this.flagX = false;
    }
    if (newNum <= 0) {
      this.flagX = true;
    }
    return this.flagX ? (newNum += 1) : (newNum -= 1);
  }

  updatePositionStateY(prev: number) {
    let newNum = prev;
    const maxNum = 330;
    if (newNum >= maxNum) {
      this.flagY = false;
    }
    if (newNum <= 0) {
      this.flagY = true;
    }
    return this.flagY ? (newNum += 1) : (newNum -= 1);
  }

  updateAnimationState() {
    const diff: number = 0;
    this.setState(prevState => ({
      posX: this.updatePositionStateX(prevState.posX) + makeBlur(diff),
      posY: this.updatePositionStateY(prevState.posY) + makeBlur(diff),
      bgcol: this.randomColor(),
    }));
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  // updateAnimationState() {
  //   this.setState(prevState => ({ angle: prevState.angle + 1, bgcol: this.randomColor() }));
  //   this.rAF = requestAnimationFrame(this.updateAnimationState);
  // }

  render() {
    return <Canvas angle={this.state.angle} posx={this.state.posX} posy={this.state.posY} bgcol={this.state.bgcol} />;
  }
}
