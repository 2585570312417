import React from 'react';
import { runInContext } from 'vm';

export class CanvasStatic extends React.PureComponent {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const randomCol = () => {
      const r = Math.floor(Math.random() * 85);
      const g = Math.floor(Math.random() * 85);
      const b = Math.floor(Math.random() * 85);
      return `rgb(${r},${g},${b})`;
    };
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = (canvas.width = window.innerWidth);
    const height = (canvas.height = window.innerHeight);
    ctx.save();
    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'source-over';
    ctx.fillStyle = 'hsla(217, 35%, 5%, .8)';
    ctx.fillRect(0, 0, width, height);
    ctx.restore();

    const dots = [];
    const density = 40;
    const baseSize = 15;
    const diffSize = 35;
    const mw = width / 2;
    const mh = height / 2;
    const diff = 140;

    const min = 1;
    const max = 2;
    const flag = Math.floor(Math.random() * (max + 1 - min)) + min;

    const Dot = function() {
      this.size = Math.floor(Math.random() * diffSize) + baseSize;
      this.color = randomCol();
      this.pos = {
        // 位置
        x: mw + Math.random() * diff - Math.random() * diff,
        y: mh + Math.random() * diff - Math.random() * diff,
      };
    };
    Dot.prototype = {
      update() {
        this.draw();
      },

      draw() {
        ctx.globalCompositeOperation = 'lighter';
        ctx.fillStyle = this.color;
        ctx.beginPath();
        switch (flag) {
          case 1:
            ctx.arc(this.pos.x, this.pos.y, this.size, 0, 2 * Math.PI, false);
            break;
          case 2:
            const rf = 150;
            ctx.moveTo(this.pos.x, this.pos.y);
            ctx.lineTo(this.pos.x + Math.random() * rf - Math.random() * rf, this.pos.y + Math.random() * rf - Math.random() * rf);
            ctx.lineTo(this.pos.x + Math.random() * rf - Math.random() * rf, this.pos.y + Math.random() * rf - Math.random() * rf);
            ctx.closePath();
            break;
          default:
            ctx.arc(this.pos.x, this.pos.y, this.size, 0, 2 * Math.PI, false);
        }
        ctx.fill();
        ctx.restore();
      },
    };

    function update() {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      for (let i = 0; i < density; i += 1) {
        dots[i].update();
      }
    }

    function init() {
      for (let i = 0; i < density; i += 1) {
        dots.push(new Dot());
      }
      update();
    }
    init();
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}
