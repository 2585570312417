import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { CanvasAnimation } from './CanvasAnimation';
import theme from '../../config/Theme';
import { media } from '../utils/media';
import split from 'lodash/split';
import './layout.scss';

const GlobalStyle = createGlobalStyle`
  ::selection {
    color: ${theme.colors.primary};
  }
  body {
    background: ${theme.colors.bg};
    color: ${theme.colors.primary};
    @media ${media.phone} {
      font-size: 14px;
    }
  }
  a {
    color: ${theme.colors.primary};
    text-decoration: underline;
    transition: all ${theme.transitions.normal};
  }
  a:hover {
    color: ${theme.colors.grey.dark};
  }
  h1, h2, h3, h4 {
    color: ${theme.colors.primary};
  }
  blockquote {
    font-style: italic;
    position: relative;
  }

  blockquote:before {
    content: "";
    position: absolute;
    color: ${theme.colors.primary};
    height: 100%;
    width: 6px;
    margin-left: -1.6rem;
  }
  label {
    margin-bottom: .5rem;
    color: ${theme.colors.primary};
  }
  input, textarea {
    border-radius: .5rem;
    border: none;
    background: rgba(0, 0, 0, 0.05);
    padding: .25rem 1rem;
    &:focus {
      outline: none;
    }
  }
  .textRight {
    text-align:right;
  }
`;

const HeaderMenu = styled.header`
  position: fixed;
  left: 0.75rem;
  top: 0.75rem;
  z-index: 9999;
  h1 {
    font-size: 0.75rem;
    margin: 0;
    a {
      display: inline-block;
      margin-right: 1rem;
    }
  }
`;

const Footer = styled.footer`
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  right: 10px;
  span,
  a {
    font-size: 0.75rem;
    text-indent: 0.75em;
    display: inline-block;
  }
  @media ${media.tablet} {
    text-align: center;
    span {
      font-size: 0.75rem;
    }
  }
  @media ${media.phone} {
    text-align: center;
    span {
      font-size: 0.75rem;
    }
  }
`;

export class Layout extends React.PureComponent<{}> {
  public render() {
    const { children } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              buildTime(formatString: "DD.MM.YYYY")
            }
          }
        `}
        render={data => (
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <GlobalStyle />
              <HeaderMenu>
                <h1>
                  <Link to="/">hitima</Link>
                  <Link to="/blog">Blog</Link>
                  <Link to="/contact">Contact</Link>
                </h1>
              </HeaderMenu>
              {children}
              <Footer>
                <Link to="/">Home</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/contact">Contact</Link>
              </Footer>
            </React.Fragment>
          </ThemeProvider>
        )}
      />
    );
  }
}
