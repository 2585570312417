import React from 'react';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import rgba from 'polished/lib/color/rgba';
import { media } from '../utils/media';
import config from '../../config/SiteConfig';

const HeaderWrapper: any = styled.header`
  position: relative;
  margin: 5rem auto;
  max-width: 100rem;
  padding: 0 6rem;
  @media ${media.tablet} {
    padding: 3rem 3rem;
  }
  @media ${media.phone} {
    padding: 2rem 1.5rem;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 999;
  a {
    color: white;
    &:hover {
      opacity: 0.85;
      color: white;
    }
  }
`;

interface Props {
  children: any;
}

export class Header extends React.PureComponent<Props> {
  public render() {
    return (
      <HeaderWrapper>
        <Content>{this.props.children}</Content>
      </HeaderWrapper>
    );
  }
}
